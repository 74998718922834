var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Layout", [
    !_vm.$apollo.queries.places.loading
      ? _c(
          "div",
          { staticClass: "animated fadeIn" },
          [
            _c(
              "b-row",
              { staticClass: "pb-4" },
              [
                _c("b-col", { attrs: { cols: "6" } }, [
                  _c("h2", [_vm._v(_vm._s(_vm.$i18n.t("place.title.page")))]),
                ]),
                _c(
                  "b-col",
                  { attrs: { cols: "6" } },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "float-right",
                        attrs: { variant: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.goTo(null)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$i18n.t("place.button.newPlace")) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-card",
              { attrs: { "body-class": "p-0" } },
              [
                _c(
                  "b-card-body",
                  { staticClass: "p-0" },
                  [
                    _c("b-table", {
                      attrs: {
                        responsive: "",
                        striped: "",
                        hover: "",
                        items: _vm.places.items,
                        fields: _vm.fields,
                      },
                      on: { "row-clicked": _vm.goTo },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "cell(name)",
                            fn: function (data) {
                              return [
                                _c("strong", [_vm._v(_vm._s(data.item.name))]),
                              ]
                            },
                          },
                          {
                            key: "cell(address)",
                            fn: function (data) {
                              return [
                                _c("span", { staticClass: "text-muted" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(data.item.address_street_name) +
                                      ", " +
                                      _vm._s(data.item.address_street_number) +
                                      " "
                                  ),
                                  _c("br"),
                                  _c("span", { staticClass: "small" }, [
                                    _vm._v(
                                      _vm._s(data.item.address_neighborhood) +
                                        ", " +
                                        _vm._s(data.item.address_city) +
                                        ", " +
                                        _vm._s(data.item.address_state)
                                    ),
                                  ]),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "cell(capacity_throughput)",
                            fn: function (data) {
                              return [
                                _c("span", { staticClass: "text-muted" }, [
                                  _vm._v(
                                    _vm._s(data.item.capacity_throughput) +
                                      " / dia"
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "cell(capacity_total)",
                            fn: function (data) {
                              return [
                                _c("span", { staticClass: "text-muted" }, [
                                  _vm._v(
                                    _vm._s(data.item.capacity_total) +
                                      "/" +
                                      _vm._s(data.item.capacity_current) +
                                      " (" +
                                      _vm._s(
                                        _vm.calcCapacity(
                                          data.item.capacity_total,
                                          data.item.capacity_current
                                        )
                                      ) +
                                      ")"
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "cell(status)",
                            fn: function (data) {
                              return [
                                _c(
                                  "b-badge",
                                  {
                                    attrs: {
                                      variant: _vm.getStatus(data.item.status),
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(data.item.status) + " ")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1154632821
                      ),
                    }),
                  ],
                  1
                ),
                _vm.places.items.length === 0
                  ? _c(
                      "b-card-body",
                      [
                        _c("content-controll", {
                          attrs: { service: _vm.places },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c("modal-place", { attrs: { modal_data: _vm.place_modal_data } }),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "animated fadeIn" },
          [_c("content-loading")],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }