<template>
  <div>
    <b-form-row>
      <b-form-group
        id="fg-kovi-address-autocomplete"
        :label="$t('drivers.labels.address')"
        label-for="kovi-address-autocomplete"
        class="col-sm-12"
      >
        <kovi-address-autocomplete
          id="kovi-address-autocomplete"
          class-name="form-control"
          @input="onPlaceChanged"
        />
      </b-form-group>
    </b-form-row>

    <b-form-row>
      <b-form-group
        id="fg-driver-address-country"
        :label="$t('drivers.labels.country')"
        label-for="driver-address-country"
        class="col-md-2 col-sm-12"
      >
        <b-form-select
          ref="country"
          :disabled="disabled"
          :value="data.country"
          :options="form_country_options"
          :state="checkField(data.country)"
          @change="updateAddressModel('country', $event)"
        />
      </b-form-group>
      <mask-input
        ref="postal_code"
        v-model.lazy="data.postal_code"
        :mask-label="$t('drivers.labels.cep')"
        parent-class="col-md-2 col-sm-12"
        :disabled="disabled"
        :mask="$t('drivers.masks.postalCode')"
        :field-state="checkField(data.postal_code)"
        @input="updateAddressModel('postal_code', $event)"
      />
      <b-form-group
        id="fg-driver-address-street"
        :label="$t('drivers.labels.publicPlace')"
        label-for="driver-address-street"
        class="col-md-5 col-sm-12"
      >
        <b-form-input
          id="driver-address-street"
          ref="street_name"
          v-model="data.street_name"
          :disabled="disabled"
          :readonly="readonly"
          :state="checkField(data.street_name)"
          type="text"
          @input="updateAddressModel('street_name', $event)"
        />
      </b-form-group>
      <mask-input
        ref="street_number"
        v-model.lazy="data.street_number"
        :mask-label="$t('drivers.labels.number')"
        parent-class="col-md-1 col-sm-12"
        :disabled="street_no_number || disabled"
        mask="#####"
        :field-state="checkField(data.street_number)"
        @input="updateAddressModel('street_number', $event)"
      />
      <b-form-group
        id="fg-driver-address-no-number"
        style="color: white"
        label-for="driver-address-no-number"
        class="col-md-2 col-sm-12"
      >
        <template slot="label">
          &nbsp;
        </template>
        <b-form-checkbox
          ref="street_no_number"
          v-model="data.street_no_number"
          :disabled="disabled"
          class="mt-1"
          @change="checkNoNumber"
          @input="updateAddressModel('street_no_number', $event)"
        >
          {{ $t('drivers.texts.noNumber') }}
        </b-form-checkbox>
      </b-form-group>
    </b-form-row>
    <b-form-row>
      <b-form-group
        id="fg-driver-address-street-details"
        :label="$t('drivers.labels.complement')"
        label-for="driver-address-street-details"
        class="col-md-2 col-sm-12"
      >
        <b-form-input
          id="driver-address-street-details"
          ref="street_details"
          v-model="data.street_details"
          :disabled="disabled"
          type="text"
          @input="updateAddressModel('street_details', $event)"
        />
      </b-form-group>
      <b-form-group
        id="fg-driver-address-neighborhood"
        :label="$t('drivers.labels.district')"
        label-for="driver-address-neighborhood"
        class="col-md-3 col-sm-12"
      >
        <b-form-input
          id="driver-address-neighborhood"
          ref="neighborhood"
          v-model="data.neighborhood"
          :disabled="disabled"
          :readonly="readonly"
          type="text"
          :state="checkField(data.neighborhood)"
          @input="updateAddressModel('neighborhood', $event)"
        />
      </b-form-group>
      <b-form-group
        id="fg-driver-address-city"
        :label="$t('drivers.labels.city')"
        label-for="driver-address-city"
        class="col-md-4 col-sm-12"
      >
        <b-form-input
          id="driver-address-city"
          ref="city"
          v-model="data.city"
          :disabled="disabled"
          :readonly="readonly"
          :state="checkField(data.city)"
          type="text"
          @input="updateAddressModel('city', $event)"
        />
      </b-form-group>
      <b-form-group
        id="fg-driver-address-state"
        :label="$t('drivers.labels.state')"
        label-for="driver-address-state"
        class="col-md-3 col-sm-12"
      >
        <b-form-input
          ref="state"
          v-model="data.state"
          :disabled="disabled"
          :readonly="readonly"
          :state="checkField(data.state)"
          @input="updateAddressModel('state', $event)"
        />
      </b-form-group>
    </b-form-row>
  </div>
</template>

<script>
/* eslint-disable vue/prop-name-casing */
import { AddressStatesList, AddressStatesListMexico } from '@utils/address';
import { isMexico, isBrazil } from '@utils/helper-region';

export default {
  name: 'AddressEdit',
  components: {
    KoviAddressAutocomplete: () => import('@components/shared/kovi-address-autocomplete')
  },
  props: {
    validation: {
      type: [String, Boolean],
      default: null,
    },
    street_name: {
      type: String,
      required: true,
      default: '',
    },
    street_number: {
      type: String,
      required: false,
      default: '',
    },
    street_no_number: {
      type: Boolean,
      default: false,
      required: true,
    },
    street_details: {
      type: String,
      required: false,
      default: '',
    },
    neighborhood: {
      type: String,
      required: false,
      default: '',
    },
    city: {
      type: String,
      required: true,
      default: '',
    },
    state: {
      type: String,
      required: true,
      default: '',
    },
    postal_code: {
      type: String,
      required: true,
      default: '',
    },
    country: {
      type: String,
      required: true,
      default: 'BR',
    },
    block_ui: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      data: {
        street_name: '',
        street_number: '',
        street_no_number: '',
        street_details: '',
        neighborhood: '',
        city: '',
        state: '',
        postal_code: '',
        country: '',
        lat: '',
        lng: ''
      },
      disable_form: true,
      readonly: false,
      loading: false,
      form_states_options: [],
      form_country_options: [],
      maskLetterNumberBarUpper: {
        F: {
          pattern: /[a-zA-Z0-9/]/,
          transform: v => v.toLocaleUpperCase(),
        },
      },
    };
  },
  computed: {
    disabled() {
      return this.block_ui || this.loading || this.disable_form
    }
  },
  mounted() {
    this.data.street_name = this.street_name;
    this.data.street_number = this.street_number;
    this.data.street_no_number = this.street_no_number;
    this.data.street_details = this.street_details;
    this.data.neighborhood = this.neighborhood;
    this.data.city = this.city;
    this.data.state = this.state;
    this.data.postal_code = this.postal_code;
    this.data.country = this.country;
    this.data.readonly = this.readonly;

    if (isBrazil()) {
      this.form_states_options = AddressStatesList;
      this.form_country_options = [{ text: 'Brasil', value: 'BR' }];
    } else if (isMexico()) {
      this.form_country_options = [{ text: 'México', value: 'MX' }];
      this.form_states_options = AddressStatesListMexico;
    }
  },
  methods: {
    onPlaceChanged(address) {

      const componentForm = {
        administrative_area_level_1: 'short_name', //estado
        administrative_area_level_2: 'long_name', //cidade
        country: 'short_name',
        locality: 'long_name', //cidade
        postal_code: 'short_name',
        route: 'long_name', //rua
        street_number: 'short_name',
        sublocality_level_1: 'long_name', //bairro
      };

      let addressFormat = {};

      for (let i = 0; i < address.address_components.length; i++) {
        let addressType = address.address_components[i].types[0];
        if (componentForm[addressType]) {
          let val = address.address_components[i][componentForm[addressType]];
          addressFormat[addressType] = val;
        }
      }

      const postalCode = (
        typeof addressFormat.postal_code === 'string'
        ? addressFormat.postal_code.replace('-', '').replace('.', '')
        : ''
      );

      this.data = Object.assign({}, this.data, {
        street_number: addressFormat.street_number || '',
        street_name: addressFormat.route || '',
        neighborhood: addressFormat.sublocality_level_1 || '',
        city: addressFormat.administrative_area_level_2 || addressFormat.locality || '',
        state: addressFormat.administrative_area_level_1 || '',
        country: addressFormat.country || '',
        postal_code: postalCode,
      });

      this.data.lat = address.geometry.location.lat()
      this.data.lng = address.geometry.location.lng()

      this.disable_form = false
      this.updateState();
    },
    checkField(field) {
      return this.validation ? !!field : null;
    },
    checkNoNumber($checked) {
      if ($checked) {
        this.data.street_number = 'N/A';
      } else {
        this.data.street_number = '';
      }
    },
    updateAddressModel($target, $event) {
      this.$emit('update-form-data', $target, $event);
    },
    updateState() {
      this.updateAddressModel('country', this.data.country);
      this.updateAddressModel('postal_code', this.data.postal_code);
      this.updateAddressModel('street_name', this.data.street_name);
      this.updateAddressModel('street_number', this.data.street_number);
      this.updateAddressModel('street_no_number', this.data.street_no_number);
      this.updateAddressModel('street_details', this.data.street_details);
      this.updateAddressModel('neighborhood', this.data.neighborhood);
      this.updateAddressModel('city', this.data.city);
      this.updateAddressModel('state', this.data.state);

      this.$emit('address-lat-lng', {
        lat: this.data.lat,
        lng: this.data.lng
      });
    },
  },
};
</script>
