<script>
import AddressEdit from '@components/address-edit';
import {
  enumStatus
} from '@utils/place';

import PLACE_CREATE from '@graphql/place/mutations/create.gql';
import PLACE_UPDATE from '@graphql/place/mutations/update.gql';

import { i18next } from '@translate/i18n';

export default {
  name: 'ModalPlace',
  components: {
    AddressEdit,
  },
  props: {
    modal_data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data: {
        id: null,
        name: '',
        address_street_name: '',
        address_street_number: '',
        address_street_no_number: false,
        address_street_details: '',
        address_neighborhood: '',
        address_city: '',
        address_state: '',
        address_postal_code: '',
        address_country: '',
        capacity_total: 0,
        capacity_throughput: 0,
        capacity_current: 0,
        status: '',
        modal: {
          title: '',
          save_button: '',
          save_success_title: '',
        },
        type: {
          delivery: false,
          devolution: false,
          productive: false
        }
      },
      placeType: [],
      block_ui: false,
      form_status_options: enumStatus,
    };
  },
  methods: {
    handleOpen() {
      this.block_ui = false;
      if (this.modal_data.id) {
        this.data.id = this.modal_data.id;
        this.data.name = this.modal_data.name;
        this.data.address_street_name = this.modal_data.address_street_name;
        this.data.address_street_number = this.modal_data.address_street_number;
        this.data.address_street_no_number =
        this.modal_data.address_street_no_number == 'true' ? true : false;
        this.data.address_street_details = this.modal_data.address_street_details;
        this.data.address_neighborhood = this.modal_data.address_neighborhood;
        this.data.address_city = this.modal_data.address_city;
        this.data.address_state = this.modal_data.address_state;
        this.data.address_postal_code = this.modal_data.address_postal_code;
        this.data.address_country = this.modal_data.address_country;
        this.data.capacity_total = parseInt(this.modal_data.capacity_total) || 0;
        this.data.capacity_throughput = parseInt(this.modal_data.capacity_throughput) || 0;
        this.data.capacity_current = this.modal_data.capacity_current;
        this.data.status = this.modal_data.status;
        this.data.modal.title = i18next.t('place.title.modalUpdatePlace');
        this.data.modal.save_button = i18next.t('place.button.updatePlace');
        this.data.modal.save_success_title = i18next.t('place.message.updateSuccess');

        this.data.type = this.modal_data.type;
        this.placeType = [];
        this.startType(this.modal_data.type)
      } else {
        this.data.id = null;
        this.data.name = '';
        this.data.address_street_name = '';
        this.data.address_street_number = '';
        this.data.address_street_no_number = false;
        this.data.address_street_details = '';
        this.data.address_neighborhood = '';
        this.data.address_city = '';
        this.data.address_state = '';
        this.data.address_postal_code = '';
        this.data.address_country = 'BR';
        this.data.capacity_total = 0;
        this.data.capacity_throughput = 0;
        this.data.capacity_current = 0;
        this.data.status = 'INACTIVE';
        this.data.modal.title = i18next.t('place.title.modalNewPlace');
        this.data.modal.save_button = i18next.t('place.button.createPlace');
        this.data.modal.save_success_title = i18next.t('place.message.createSuccess');

        this.data.type = {
          delivery: false,
          devolution: false,
          productive: false

        };
        this.placeType = [];
      }
    },
    checkNoNumber(checked) {
      if (checked) {
        this.data.address_street_number = 'N/A';
      } else {
        this.data.address_street_number = '';
      }
    },
    updateAddressModel(_item, _data) {
      this.data['address_' + _item] = _data;
    },
    async doCreatePlace() {
      const original_data = this.data;
      this.block_ui = true;
      this.$apollo
        .mutate({
          mutation: PLACE_CREATE,
          variables: {
            name: this.data.name,
            address_street_name: this.data.address_street_name,
            address_street_number: this.data.address_street_number,
            address_street_no_number: this.data.address_street_no_number,
            address_street_details: this.data.address_street_details,
            address_neighborhood: this.data.address_neighborhood,
            address_city: this.data.address_city,
            address_state: this.data.address_state,
            address_postal_code: this.data.address_postal_code,
            address_country: this.data.address_country,
            capacity_total: parseInt(this.data.capacity_total) || 0,
            capacity_throughput: parseInt(this.data.capacity_throughput) || 0,
            status: this.data.status,
            type: this.data.type,
          },
        })
        .then(() => {
          this.$root.$emit('bv::hide::modal', 'modalPlace');
          this.$swal({
            type: 'success',
            title: this.data.title_success,
            showConfirmButton: false,
            timer: 2500,
          }).then(() => {
            this.$parent.$parent.$apollo.queries.places.refresh();
          });
        })
        .catch(() => {
          this.data = original_data;
          this.$swal({
            type: 'error',
            title: i18next.t('place.message.createError.title'),
            text: i18next.t('place.message.createError.text'),
            showConfirmButton: false,
            timer: 2500,
          }).then(() => {
            this.block_ui = false;
          });
        });
    },
    async doUpdatePlace() {
      const original_data = this.data;
      this.block_ui = true;
      this.$apollo
        .mutate({
          mutation: PLACE_UPDATE,
          variables: {
            id: this.data.id,
            name: this.data.name,
            address_street_name: this.data.address_street_name,
            address_street_number: this.data.address_street_number || '',
            address_street_no_number: this.data.address_street_no_number,
            address_street_details: this.data.address_street_details || '',
            address_neighborhood: this.data.address_neighborhood,
            address_city: this.data.address_city,
            address_state: this.data.address_state,
            address_postal_code: this.data.address_postal_code,
            address_country: this.data.address_country,
            capacity_total: parseInt(this.data.capacity_total) || 0,
            capacity_throughput: parseInt(this.data.capacity_throughput) || 0,
            status: this.data.status,
            type: this.data.type,
          },
        })
        .then(() => {
          this.$root.$emit('bv::hide::modal', 'modalPlace');
          this.$swal({
            type: 'success',
            title: this.data.title_success,
            showConfirmButton: false,
            timer: 2500,
          }).then(() => {
            this.$parent.$parent.$apollo.queries.places.refresh();
          });
        })
        .catch(() => {
          this.data = original_data;
          this.$swal({
            type: 'error',
            title: i18next.t('place.message.updateError.title'),
            text: i18next.t('place.message.updateError.text'),
            showConfirmButton: false,
            timer: 2500,
          }).then(() => {
            this.block_ui = false;
          });
        });
    },
    async handleSubmit(e) {
      e.preventDefault();
      if (this.data.id) {
        this.doUpdatePlace();
      } else {
        this.doCreatePlace();
      }
    },
    startType(value) {
      if (value.delivery) this.placeType.push('delivery')
      if (value.devolution) this.placeType.push('devolution')
      if (value.productive) this.placeType.push('productive')
    },
  },
  watch: {
    placeType(value) {
      const currentType = value
      currentType.includes('delivery') ? this.data.type.delivery = true : this.data.type.delivery = false
      currentType.includes('devolution') ? this.data.type.devolution = true : this.data.type.devolution = false
      currentType.includes('productive') ? this.data.type.productive = true : this.data.type.productive = false
    }
  },
};
</script>

<template>
  <!-- Add Place Modal -->
  <b-modal id="modalPlace" lazy no-fade
           size="lg" :title="data.modal.title" :ok-title="data.modal.save_button"
           cancel-title="Cancelar" @show="handleOpen" @ok="handleSubmit"
  >
    <b-form validate>
      <b-form-row>
        <b-form-group id="fg-place-name" :label="$t('place.form.name')" label-for="place-name"
                      class="col-md-4 col-sm-12"
        >
          <b-form-input id="place-name" v-model.trim="data.name" :disabled="this.block_ui"
                        type="text"
          />
        </b-form-group>
        <b-form-group id="fg-place-capacity-total" :label="$t('place.form.capacityTotal')" label-for="place-capacity-total"
                      class="col-md-2 col-sm-12"
        >
          <the-mask id="place-capacity-total" v-model.trim="data.capacity_total" :disabled="block_ui"
                    autocomplete="capacity" class="form-control" type="text"
                    mask="####"
          />
        </b-form-group>
        <b-form-group id="fg-place-capacity-throughput" :label="$t('place.form.capacityThroughput')" label-for="place-capacity-throughput"
                      class="col-md-2 col-sm-12"
        >
          <b-input-group>
            <b-input-group-text slot="append">
              {{ $i18n.t('place.form.capacityThroughputAppend') }}
            </b-input-group-text>
            <the-mask id="place-capacity-throughput" v-model.trim="data.capacity_throughput" :disabled="block_ui"
                      autocomplete="throughput" class="form-control" type="text"
                      mask="###"
            />
          </b-input-group>
        </b-form-group>
        <b-form-group id="fg-place-capacity-current" :label="$t('place.form.capacityCurrent')" label-for="place-capacity-current"
                      class="col-md-2 col-sm-12"
        >
          <b-input-group>
            <b-input-group-text slot="append">
              {{ $i18n.t('place.form.capacityCurrentAppend') }}
            </b-input-group-text>
            <b-form-input id="place-capacity-current" v-model.trim="data.capacity_current" disabled
                          type="number"
            />
          </b-input-group>
        </b-form-group>
        <b-form-group id="fg-place-status" :label="$t('place.form.status')" label-for="place-status"
                      class="col-md-2 col-sm-12"
        >
          <b-form-select v-model="data.status" :disabled="this.block_ui" :options="form_status_options" />
        </b-form-group>
      </b-form-row>

      <hr>

      <address-edit :street_name="data.address_street_name" :street_number="data.address_street_number" :street_no_number="data.address_street_no_number"
                    :street_details="data.address_street_details" :neighborhood="data.address_neighborhood"
                    :city="data.address_city" :state="data.address_state" :postal_code="data.address_postal_code"
                    :country="data.address_country" :block_ui="this.block_ui" @update-form-data="this.updateAddressModel"
      />

      <b-form-row>
        <b-form-group id="fg-place-type" :label="$t('place.form.type')" label-for="place-type" class="col-md-6 col-sm-12" >
          <b-form-checkbox-group id="checkbox-place" v-model="placeType" :disabled="this.block_ui">
            <b-form-checkbox value="delivery">{{ $i18n.t('place.form.checkboxType.delivery') }}</b-form-checkbox>
            <b-form-checkbox value="devolution">{{ $i18n.t('place.form.checkboxType.devolution') }}</b-form-checkbox>
            <b-form-checkbox value="productive">{{ $i18n.t('place.form.checkboxType.productive') }}</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </b-form-row>
    </b-form>
  </b-modal>
</template>
