var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-form-row",
        [
          _c(
            "b-form-group",
            {
              staticClass: "col-sm-12",
              attrs: {
                id: "fg-kovi-address-autocomplete",
                label: _vm.$t("drivers.labels.address"),
                "label-for": "kovi-address-autocomplete",
              },
            },
            [
              _c("kovi-address-autocomplete", {
                attrs: {
                  id: "kovi-address-autocomplete",
                  "class-name": "form-control",
                },
                on: { input: _vm.onPlaceChanged },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c(
            "b-form-group",
            {
              staticClass: "col-md-2 col-sm-12",
              attrs: {
                id: "fg-driver-address-country",
                label: _vm.$t("drivers.labels.country"),
                "label-for": "driver-address-country",
              },
            },
            [
              _c("b-form-select", {
                ref: "country",
                attrs: {
                  disabled: _vm.disabled,
                  value: _vm.data.country,
                  options: _vm.form_country_options,
                  state: _vm.checkField(_vm.data.country),
                },
                on: {
                  change: function ($event) {
                    return _vm.updateAddressModel("country", $event)
                  },
                },
              }),
            ],
            1
          ),
          _c("mask-input", {
            ref: "postal_code",
            attrs: {
              "mask-label": _vm.$t("drivers.labels.cep"),
              "parent-class": "col-md-2 col-sm-12",
              disabled: _vm.disabled,
              mask: _vm.$t("drivers.masks.postalCode"),
              "field-state": _vm.checkField(_vm.data.postal_code),
            },
            on: {
              input: function ($event) {
                return _vm.updateAddressModel("postal_code", $event)
              },
            },
            model: {
              value: _vm.data.postal_code,
              callback: function ($$v) {
                _vm.$set(_vm.data, "postal_code", $$v)
              },
              expression: "data.postal_code",
            },
          }),
          _c(
            "b-form-group",
            {
              staticClass: "col-md-5 col-sm-12",
              attrs: {
                id: "fg-driver-address-street",
                label: _vm.$t("drivers.labels.publicPlace"),
                "label-for": "driver-address-street",
              },
            },
            [
              _c("b-form-input", {
                ref: "street_name",
                attrs: {
                  id: "driver-address-street",
                  disabled: _vm.disabled,
                  readonly: _vm.readonly,
                  state: _vm.checkField(_vm.data.street_name),
                  type: "text",
                },
                on: {
                  input: function ($event) {
                    return _vm.updateAddressModel("street_name", $event)
                  },
                },
                model: {
                  value: _vm.data.street_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.data, "street_name", $$v)
                  },
                  expression: "data.street_name",
                },
              }),
            ],
            1
          ),
          _c("mask-input", {
            ref: "street_number",
            attrs: {
              "mask-label": _vm.$t("drivers.labels.number"),
              "parent-class": "col-md-1 col-sm-12",
              disabled: _vm.street_no_number || _vm.disabled,
              mask: "#####",
              "field-state": _vm.checkField(_vm.data.street_number),
            },
            on: {
              input: function ($event) {
                return _vm.updateAddressModel("street_number", $event)
              },
            },
            model: {
              value: _vm.data.street_number,
              callback: function ($$v) {
                _vm.$set(_vm.data, "street_number", $$v)
              },
              expression: "data.street_number",
            },
          }),
          _c(
            "b-form-group",
            {
              staticClass: "col-md-2 col-sm-12",
              staticStyle: { color: "white" },
              attrs: {
                id: "fg-driver-address-no-number",
                "label-for": "driver-address-no-number",
              },
            },
            [
              _c("template", { slot: "label" }, [_vm._v("   ")]),
              _c(
                "b-form-checkbox",
                {
                  ref: "street_no_number",
                  staticClass: "mt-1",
                  attrs: { disabled: _vm.disabled },
                  on: {
                    change: _vm.checkNoNumber,
                    input: function ($event) {
                      return _vm.updateAddressModel("street_no_number", $event)
                    },
                  },
                  model: {
                    value: _vm.data.street_no_number,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "street_no_number", $$v)
                    },
                    expression: "data.street_no_number",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("drivers.texts.noNumber")) + " ")]
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c(
            "b-form-group",
            {
              staticClass: "col-md-2 col-sm-12",
              attrs: {
                id: "fg-driver-address-street-details",
                label: _vm.$t("drivers.labels.complement"),
                "label-for": "driver-address-street-details",
              },
            },
            [
              _c("b-form-input", {
                ref: "street_details",
                attrs: {
                  id: "driver-address-street-details",
                  disabled: _vm.disabled,
                  type: "text",
                },
                on: {
                  input: function ($event) {
                    return _vm.updateAddressModel("street_details", $event)
                  },
                },
                model: {
                  value: _vm.data.street_details,
                  callback: function ($$v) {
                    _vm.$set(_vm.data, "street_details", $$v)
                  },
                  expression: "data.street_details",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              staticClass: "col-md-3 col-sm-12",
              attrs: {
                id: "fg-driver-address-neighborhood",
                label: _vm.$t("drivers.labels.district"),
                "label-for": "driver-address-neighborhood",
              },
            },
            [
              _c("b-form-input", {
                ref: "neighborhood",
                attrs: {
                  id: "driver-address-neighborhood",
                  disabled: _vm.disabled,
                  readonly: _vm.readonly,
                  type: "text",
                  state: _vm.checkField(_vm.data.neighborhood),
                },
                on: {
                  input: function ($event) {
                    return _vm.updateAddressModel("neighborhood", $event)
                  },
                },
                model: {
                  value: _vm.data.neighborhood,
                  callback: function ($$v) {
                    _vm.$set(_vm.data, "neighborhood", $$v)
                  },
                  expression: "data.neighborhood",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              staticClass: "col-md-4 col-sm-12",
              attrs: {
                id: "fg-driver-address-city",
                label: _vm.$t("drivers.labels.city"),
                "label-for": "driver-address-city",
              },
            },
            [
              _c("b-form-input", {
                ref: "city",
                attrs: {
                  id: "driver-address-city",
                  disabled: _vm.disabled,
                  readonly: _vm.readonly,
                  state: _vm.checkField(_vm.data.city),
                  type: "text",
                },
                on: {
                  input: function ($event) {
                    return _vm.updateAddressModel("city", $event)
                  },
                },
                model: {
                  value: _vm.data.city,
                  callback: function ($$v) {
                    _vm.$set(_vm.data, "city", $$v)
                  },
                  expression: "data.city",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              staticClass: "col-md-3 col-sm-12",
              attrs: {
                id: "fg-driver-address-state",
                label: _vm.$t("drivers.labels.state"),
                "label-for": "driver-address-state",
              },
            },
            [
              _c("b-form-input", {
                ref: "state",
                attrs: {
                  disabled: _vm.disabled,
                  readonly: _vm.readonly,
                  state: _vm.checkField(_vm.data.state),
                },
                on: {
                  input: function ($event) {
                    return _vm.updateAddressModel("state", $event)
                  },
                },
                model: {
                  value: _vm.data.state,
                  callback: function ($$v) {
                    _vm.$set(_vm.data, "state", $$v)
                  },
                  expression: "data.state",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }