<template>
  <Layout>
    <div v-if="!$apollo.queries.places.loading" class="animated fadeIn">
      <b-row class="pb-4">
        <b-col cols="6">
          <h2>{{ $i18n.t('place.title.page') }}</h2>
        </b-col>
        <b-col cols="6">
          <b-button variant="primary" class="float-right" @click="goTo(null)">
            {{ $i18n.t('place.button.newPlace') }}
          </b-button>
        </b-col>
      </b-row>
      <b-card body-class="p-0">
        <b-card-body class="p-0">
          <b-table
            responsive
            striped
            hover
            :items="places.items"
            :fields="fields"
            @row-clicked="goTo"
          >
            <template v-slot:cell(name)="data">
              <strong>{{ data.item.name }}</strong>
            </template>
            <template v-slot:cell(address)="data">
              <span class="text-muted">
                {{ data.item.address_street_name }}, {{ data.item.address_street_number }}
                <br>
                <span
                  class="small"
                >{{ data.item.address_neighborhood }}, {{ data.item.address_city }}, {{ data.item.address_state }}</span>
              </span>
            </template>
            <template v-slot:cell(capacity_throughput)="data">
              <span class="text-muted">{{ data.item.capacity_throughput }} / dia</span>
            </template>
            <template v-slot:cell(capacity_total)="data">
              <span
                class="text-muted"
              >{{ data.item.capacity_total }}/{{ data.item.capacity_current }} ({{ calcCapacity(data.item.capacity_total, data.item.capacity_current) }})</span>
            </template>
            <template v-slot:cell(status)="data">
              <b-badge :variant="getStatus(data.item.status)">
                {{ data.item.status }}
              </b-badge>
            </template>
          </b-table>
        </b-card-body>
        <b-card-body v-if="places.items.length === 0">
          <content-controll :service="places" />
        </b-card-body>
      </b-card>
      <modal-place :modal_data="place_modal_data" />
    </div>
    <div v-else class="animated fadeIn">
      <content-loading />
    </div>
  </Layout>
</template>


<script>
import Layout from '@layouts/main';
import ContentControll from '@components/content-controll';
import ContentLoading from '@components/content-loading';
import ModalPlace from '@components/modals/place';
import { i18next } from '@translate/i18n';

import PLACE_LIST from '@graphql/place/queries/list.gql';

export default {
  name: 'AdminPlaces',
  page: {
    title: 'Administrar Pátios',
  },
  components: {
    Layout,
    ContentControll,
    ContentLoading,
    ModalPlace,
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: `${i18next.t('place.table.name')}`,
          sortable: true,
          class: 'w-25',
        },
        {
          key: 'address',
          label: `${i18next.t('place.table.address')}`,
        },
        {
          key: 'capacity_total',
          label: `${i18next.t('place.table.capacityTotal')}`,
        },
        {
          key: 'capacity_throughput',
          label: `${i18next.t('place.table.capacityThroughput')}`,
        },
        {
          key: 'status',
          label: '',
          class: 'text-right',
        },
      ],
      place_modal_data: {
        id: null,
        name: '',
        address_street_name: '',
        address_street_number: '',
        address_street_no_number: false,
        address_street_details: '',
        address_neighborhood: '',
        address_city: '',
        address_state: '',
        address_postal_code: '',
        address_country: '',
        capacity_total: 0,
        capacity_throughput: 0,
        capacity_current: 0,
        status: '',
      },
    };
  },
  apollo: {
    places: {
      query: PLACE_LIST,
    },
  },
  methods: {
    getStatus(status) {
      return status === 'ACTIVE' ? 'success' : 'secondary';
    },
    calcCapacity(total, current) {
      return Math.round((current / total) * 100, 1) + '%';
    },
    goTo(_item = null) {
      if (_item === null) {
        this.place_modal_data.id = null;
      } else {
        this.place_modal_data.id = _item.id;
        this.place_modal_data.name = _item.name;
        this.place_modal_data.address_street_name = _item.address_street_name;
        this.place_modal_data.address_street_number =
          _item.address_street_number;
        this.place_modal_data.address_street_no_number =
          _item.address_street_no_number;
        this.place_modal_data.address_street_details =
          _item.address_street_details;
        this.place_modal_data.address_neighborhood = _item.address_neighborhood;
        this.place_modal_data.address_city = _item.address_city;
        this.place_modal_data.address_state = _item.address_state;
        this.place_modal_data.address_postal_code = _item.address_postal_code;
        this.place_modal_data.address_country = _item.address_country;
        this.place_modal_data.capacity_total = _item.capacity_total;
        this.place_modal_data.capacity_throughput = _item.capacity_throughput;
        this.place_modal_data.capacity_current = _item.capacity_current;
        this.place_modal_data.status = _item.status;
        this.place_modal_data.type = _item.type;
      }
      this.$root.$emit('bv::show::modal', 'modalPlace');
    },
  },
};
</script>
