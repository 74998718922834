export const AddressStatesList = [
  {text: 'Acre', value: 'AC'},
  {text: 'Alagoas', value: 'AL'},
  {text: 'Amapá', value: 'AP'},
  {text: 'Amazonas', value: 'AM'},
  {text: 'Bahia', value: 'BA'},
  {text: 'Ceará', value: 'CE'},
  {text: 'Distrito Federal', value: 'DF'},
  {text: 'Espírito Santo', value: 'ES'},
  {text: 'Goiás', value: 'GO'},
  {text: 'Maranhão', value: 'MA'},
  {text: 'Mato Grosso', value: 'MT'},
  {text: 'Mato Grosso do Sul', value: 'MS'},
  {text: 'Minas Gerais', value: 'MG'},
  {text: 'Pará', value: 'PA'},
  {text: 'Paraíba', value: 'PB'},
  {text: 'Paraná', value: 'PR'},
  {text: 'Pernambuco', value: 'PE'},
  {text: 'Piauí', value: 'PI'},
  {text: 'Rio de Janeiro', value: 'RJ'},
  {text: 'Rio Grande do Norte', value: 'RN'},
  {text: 'Rio Grande do Sul', value: 'RS'},
  {text: 'Rondônia', value: 'RO'},
  {text: 'Roraima', value: 'RR'},
  {text: 'Santa Catarina', value: 'SC'},
  {text: 'São Paulo', value: 'SP'},
  {text: 'Sergipe', value: 'SE'},
  {text: 'Tocantins', value: 'TO'}
];

export const AddressStatesListMexico = [
  { text: 'Aguascalientes', value: 'AG' },
  { text: 'Baja California', value: 'BC' },
  { text: 'Baja California Sur', value: 'BS' },
  { text: 'Campeche', value: 'CM' },
  { text: 'Coahuila de Zaragoza', value: 'Coahuila' },
  { text: 'Colima', value: 'CL' },
  { text: 'Chiapas', value: 'CS' },
  { text: 'Chihuahua', value: 'CH' },
  { text: 'Distrito Federal', value: 'DF' },
  { text: 'Durango', value: 'DG' },
  { text: 'Guanajuato', value: 'GT' },
  { text: 'Guerrero', value: 'GR' },
  { text: 'Hidalgo', value: 'HG' },
  { text: 'Jalisco', value: 'JC' },
  { text: 'México', value: 'EM' },
  { text: 'Michoacán de Ocampo', value: 'MI' },
  { text: 'Morelos', value: 'MO' },
  { text: 'Nayarit', value: 'NA' },
  { text: 'Nuevo León', value: 'NL' },
  { text: 'Oaxaca', value: 'OA' },
  { text: 'Puebla', value: 'PU' },
  { text: 'Querétaro', value: 'QT' },
  { text: 'Quintana Roo', value: 'QR' },
  { text: 'San Luis Potosí', value: 'SL' },
  { text: 'Sinaloa', value: 'SI' },
  { text: 'Sonora', value: 'SO' },
  { text: 'Tabasco', value: 'TB' },
  { text: 'Tamaulipas', value: 'TM' },
  { text: 'Tlaxcala', value: 'TL' },
  { text: 'Veracruz de Ignacio de la Llave', value: 'VE' },
  { text: 'Yucatán', value: 'YU' },
  { text: 'Zacatecas', value: 'ZA' }
];

