var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modalPlace",
        lazy: "",
        "no-fade": "",
        size: "lg",
        title: _vm.data.modal.title,
        "ok-title": _vm.data.modal.save_button,
        "cancel-title": "Cancelar",
      },
      on: { show: _vm.handleOpen, ok: _vm.handleSubmit },
    },
    [
      _c(
        "b-form",
        { attrs: { validate: "" } },
        [
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "col-md-4 col-sm-12",
                  attrs: {
                    id: "fg-place-name",
                    label: _vm.$t("place.form.name"),
                    "label-for": "place-name",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "place-name",
                      disabled: this.block_ui,
                      type: "text",
                    },
                    model: {
                      value: _vm.data.name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.data,
                          "name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "data.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  staticClass: "col-md-2 col-sm-12",
                  attrs: {
                    id: "fg-place-capacity-total",
                    label: _vm.$t("place.form.capacityTotal"),
                    "label-for": "place-capacity-total",
                  },
                },
                [
                  _c("the-mask", {
                    staticClass: "form-control",
                    attrs: {
                      id: "place-capacity-total",
                      disabled: _vm.block_ui,
                      autocomplete: "capacity",
                      type: "text",
                      mask: "####",
                    },
                    model: {
                      value: _vm.data.capacity_total,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.data,
                          "capacity_total",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "data.capacity_total",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  staticClass: "col-md-2 col-sm-12",
                  attrs: {
                    id: "fg-place-capacity-throughput",
                    label: _vm.$t("place.form.capacityThroughput"),
                    "label-for": "place-capacity-throughput",
                  },
                },
                [
                  _c(
                    "b-input-group",
                    [
                      _c(
                        "b-input-group-text",
                        { attrs: { slot: "append" }, slot: "append" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$i18n.t(
                                  "place.form.capacityThroughputAppend"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c("the-mask", {
                        staticClass: "form-control",
                        attrs: {
                          id: "place-capacity-throughput",
                          disabled: _vm.block_ui,
                          autocomplete: "throughput",
                          type: "text",
                          mask: "###",
                        },
                        model: {
                          value: _vm.data.capacity_throughput,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.data,
                              "capacity_throughput",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "data.capacity_throughput",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  staticClass: "col-md-2 col-sm-12",
                  attrs: {
                    id: "fg-place-capacity-current",
                    label: _vm.$t("place.form.capacityCurrent"),
                    "label-for": "place-capacity-current",
                  },
                },
                [
                  _c(
                    "b-input-group",
                    [
                      _c(
                        "b-input-group-text",
                        { attrs: { slot: "append" }, slot: "append" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$i18n.t("place.form.capacityCurrentAppend")
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c("b-form-input", {
                        attrs: {
                          id: "place-capacity-current",
                          disabled: "",
                          type: "number",
                        },
                        model: {
                          value: _vm.data.capacity_current,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.data,
                              "capacity_current",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "data.capacity_current",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  staticClass: "col-md-2 col-sm-12",
                  attrs: {
                    id: "fg-place-status",
                    label: _vm.$t("place.form.status"),
                    "label-for": "place-status",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      disabled: this.block_ui,
                      options: _vm.form_status_options,
                    },
                    model: {
                      value: _vm.data.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "status", $$v)
                      },
                      expression: "data.status",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("hr"),
          _c("address-edit", {
            attrs: {
              street_name: _vm.data.address_street_name,
              street_number: _vm.data.address_street_number,
              street_no_number: _vm.data.address_street_no_number,
              street_details: _vm.data.address_street_details,
              neighborhood: _vm.data.address_neighborhood,
              city: _vm.data.address_city,
              state: _vm.data.address_state,
              postal_code: _vm.data.address_postal_code,
              country: _vm.data.address_country,
              block_ui: this.block_ui,
            },
            on: { "update-form-data": this.updateAddressModel },
          }),
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "col-md-6 col-sm-12",
                  attrs: {
                    id: "fg-place-type",
                    label: _vm.$t("place.form.type"),
                    "label-for": "place-type",
                  },
                },
                [
                  _c(
                    "b-form-checkbox-group",
                    {
                      attrs: { id: "checkbox-place", disabled: this.block_ui },
                      model: {
                        value: _vm.placeType,
                        callback: function ($$v) {
                          _vm.placeType = $$v
                        },
                        expression: "placeType",
                      },
                    },
                    [
                      _c("b-form-checkbox", { attrs: { value: "delivery" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$i18n.t("place.form.checkboxType.delivery")
                          )
                        ),
                      ]),
                      _c(
                        "b-form-checkbox",
                        { attrs: { value: "devolution" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$i18n.t("place.form.checkboxType.devolution")
                            )
                          ),
                        ]
                      ),
                      _c(
                        "b-form-checkbox",
                        { attrs: { value: "productive" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$i18n.t("place.form.checkboxType.productive")
                            )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }